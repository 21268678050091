import {Divider} from "antd";
import React from "react";

const Header = ({title, rightActionComponent}) => {
	return (
		<div>
			<Divider type="horizontal" className="mt-0 mb-3"/>
			<div className="d-flex justify-content-between align-items-center">
				<h5>{title}</h5>
				{rightActionComponent}
			</div>
		</div>
	)
}

export default Header;
