import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Drawer, Form, Input, message, Select, Spin} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import Header from "../../components/Header";
import UsersService from "../../services/users.service";
import DivisionsService from "../../services/divisions.service";
import useAuth from "../../hooks/useAuth";
import ProjectsService from "../../services/projects.service";
import "../../Assets/css/ProjectsPage.css"
import ProjectCard from "../../components/ProjectsCard";

const Projects = () => {
	let {user} = useAuth();
	let [isNewProjectDrawerOpen, setIsNewProjectDrawerOpen] = useState(false);
	let [drawerFormDepsLoading, setDrawerFormDepsLoading] = useState(false);
	let [divisions, setDivisions] = useState([]);
	let [users, setUsers] = useState([]);
	let [loading, setLoading] = useState(false);
	let [projects, setProjects] = useState([]);
	let [loadingProjects, setLoadingProjects] = useState(false);


	let fetchTeamMembers = async () => {
		try {
			let response = await UsersService.getByWorkspaceId(user?.workspace?.id);
			return response?.data;
		} catch (e) {
			return []
		}
	}

	let fetchDivisions = async () => {
		try {
			let response = await DivisionsService.listDivisionsByWorkspaceId(user?.workspace?.id)
			return response.data;
		} catch (e) {
			return null
		}
	}

	let handleOpenDrawer = async () => {
		setIsNewProjectDrawerOpen(!isNewProjectDrawerOpen);
		setDrawerFormDepsLoading(true);

		fetchDivisions()
			.then(response => {
				setDivisions(response)
			})

		fetchTeamMembers()
			.then(response => {
				setUsers(response);
			})
		setDrawerFormDepsLoading(false);
	}

	let handleSubmitForm = async (values) => {
		let start_date = values["start_date"];
		let end_date = values["end_date"];

		let data = {
			...values,
			start_date: start_date.format("YYYY-MM-DD"),
			end_date: end_date.format("YYYY-MM-DD"),
		}

		setLoading(true);

		try {
			let response = await ProjectsService.create(data);
			if (response?.status === 201) {
				setProjects(prevState => [...prevState, response?.data]);
				setLoading(false);
				message.success("Project created successfully.");
				setIsNewProjectDrawerOpen(false);
			}
		} catch (e) {
			setLoading(false);
			message.error("Project not created.");
			console.error(e);
		}
	}

	useEffect(() => {
		let fetchProjects = async () => {
			setLoadingProjects(true)
			try {
				let response = await ProjectsService.getByMemberId(user?.id);
				return response?.data;
			} catch (e) {
				console.error(e);
			}
		}
		fetchProjects()
			.then(response => {
				setProjects(response)
				setLoadingProjects(false)
			})
	}, [user])

	return (
		<div>
			<Header
				title="Projects"
				rightActionComponent={
					<Button
						onClick={handleOpenDrawer}
						icon={<PlusOutlined/>}
					>
						Create project
					</Button>
				}
			/>

			{
				loadingProjects ?
					<div className="d-flex justify-content-center align-items-center py-5">
						<Spin/>
					</div> :
					(
						<div className="projects-container my-3">
							{projects.map((project, index) => (
								<ProjectCard key={index} project={project}/>
							))}
						</div>
					)
			}

			<Drawer
				title="Add and invite new team member"
				open={isNewProjectDrawerOpen}
				onClose={() => setIsNewProjectDrawerOpen(false)}
				size="large"
				destroyOnClose
				mask
			>
				{
					drawerFormDepsLoading ?
						<div className="d-flex justify-content-center align-items-center py-5">
							<Spin/>
						</div> :
						(
							<Form
								layout="vertical"
								onFinish={handleSubmitForm}
							>
								<Form.Item
									rules={[{required: true}]}
									name="name"
									label="Project name"
								>
									<Input placeholder="Project name" size="large"/>
								</Form.Item>
								<Form.Item
									rules={[{required: true}]}
									name="created_by"
									label="Created by"
									hidden={true}
									initialValue={user?.id}
								>
									<Input/>
								</Form.Item>
								<Form.Item
									rules={[{required: true}]}
									name="goal"
									label="Goal"
								>
									<Input.TextArea placeholder="Goal" size="large"/>
								</Form.Item>
								<Form.Item
									rules={[{required: true}]}
									name="purpose"
									label="Purpose"
								>
									<Input.TextArea placeholder="Purpose" size="large"/>
								</Form.Item>
								<Form.Item
									rules={[{required: true}]}
									name="start_date"
									label="Start date"
								>
									<DatePicker
										placeholder="Start date"
										size="large"
										className="w-100"
									/>
								</Form.Item>
								<Form.Item
									rules={[{required: true}]}
									name="end_date"
									label="End date"
								>
									<DatePicker placeholder="End date" size="large" className="w-100"/>
								</Form.Item>
								<Form.Item
									rules={[{required: true}]}
									name="division"
									label="Division"
								>
									<Select
										options={
											divisions?.map((division) => ({
												value: division.id,
												label: `${division.name}(${division.division_type})`,
											}))
										}
										placeholder="Divisions"
										size="large"
									/>
								</Form.Item>
								<Form.Item
									rules={[{required: true}]}
									name="members"
									label="Team"
								>
									<Select
										placeholder="Team"
										size="large"
										options={
											users?.map((i) => ({
												value: i?.id,
												label: `${i?.first_name} ${i?.last_name} ${i?.id === user?.id ? '(you)' : '' }`,
											}))
										}
										mode="multiple"
									/>
								</Form.Item>
								<Button
									disabled={loading}
									loading={loading}
									type="primary"
									size="large"
									htmlType="submit"
								>
									Create project
								</Button>
							</Form>
						)
				}
			</Drawer>
		</div>
	);
};

export default Projects;
