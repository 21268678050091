import {Button, DatePicker, Drawer, Form, Input, message, Select, Space, Spin, Table} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons";
import Header from "../../components/Header";
import DashboardInfoCard from "../../components/DashboardInfoCard";
import React, {useEffect, useState} from "react";
import ProjectsService from "../../services/projects.service";
import useAuth from "../../hooks/useAuth";
import TasksService from "../../services/tasks.service";

const Task = () => {
	let {user} = useAuth();
	let [isNewTaskDrawerOpen, setIsNewTaskDrawerOpen] = useState(false);
	let [projects, setProjects] = useState([]);
	let [drawerFormDepsLoading, setDrawerFormDepsLoading] = useState(false);
	let [members, setMembers] = useState([]);
	let [tasks, setTasks] = useState([]);
	let [createTaskBtnLoading, setCreateTaskBtnLoading] = useState(false);
	let [currentUserTasks, setCurrentUserTasks] = useState([]);
	let [loadingCurrentUserTasks, setLoadingCurrentUserTasks] = useState(false);
	let [tasksStatsLoading, setTasksStatsLoading] = useState(false);
	let [taskStats, setTaskStats] = useState({});

	let handleSubmit = async (values) => {
		let start_date = values["start_date"];
		let due_date = values["due_date"];

		let data = {
			...values,
			start_date: start_date.format("YYYY-MM-DD"),
			due_date: due_date.format("YYYY-MM-DD"),
		}

		setCreateTaskBtnLoading(true);

		try {
			let response = await TasksService.create(data);
			if (response?.status === 201) {
				setCurrentUserTasks(prevState => [...prevState, response?.data]);
				message.success("Task created successfully.");
				setCreateTaskBtnLoading(false);
				setIsNewTaskDrawerOpen(false)
			}
		} catch (e) {
			setCreateTaskBtnLoading(false);
			message.error("An error occurred.");
		}
	}

	let fetchProjects = async () => {
		try {
			let response = await ProjectsService.getByMemberId(user?.id);
			return response?.data;
		} catch (e) {
			console.error(e);
		}
	}

	let handleOpenDrawer = async () => {
		setIsNewTaskDrawerOpen(true);
		setDrawerFormDepsLoading(true);

		fetchProjects()
			.then(response => {
				setProjects(response)
			})

		setDrawerFormDepsLoading(false);
	}

	let handleChangeProject = async (value) => {
		try {
			let response = await ProjectsService.getMembersByProjectId(value);
			if (response?.status === 200) {
				setMembers(response?.data)
			}

			let _tasks = await TasksService.getTasksByProjectId(value);
			if (_tasks?.status === 200) {
				setTasks(_tasks?.data)
			}
		} catch (e) {
			console.error(e);
		}
	}

	let taskTableColumns = [
		{
			title: "Title",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Start date",
			dataIndex: "start_date",
			key: "start_date",
		},
		{
			title: "Due date",
			dataIndex: "due_date",
			key: "due_date",
		},
		{
			title: "Date completed",
			dataIndex: "completed_date",
			key: "completed_date",
		},
		{
			title: "Priority",
			dataIndex: "priority",
			key: "priority",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Is due",
			dataIndex: "is_due",
			key: "is_due",
			render: (text, record) => {
				if (record?.is_due) {
					return <span className="text-danger">Yes</span>;
				} else {
					return "No"
				}
			}
		},
		{
			title: "Action",
			dataIndex: "x",
			key: "x",
			render: (text, record) => (
				<Space direction="horizontal">
					<Button
						size="small"
						icon={<EyeOutlined style={{color: "white"}} />}
						className="bg-primary border-primary"
						disabled
					/>
					<Button
						size="small"
						icon={<EditOutlined style={{color: "white"}} />}
						className="bg-warning border-warning"
						disabled
					/>
					<Button
						size="small"
						icon={<DeleteOutlined />}
						danger
						disabled/>
				</Space>
			)
		},
	]

	useEffect(() => {
		let fetchTasks = async () => {
			setLoadingCurrentUserTasks(true);
			try {
				let response = await TasksService.getTasksByOwnerId(user?.id);
				return response?.data;
			} catch (e) {
				console.error(e);
			}
		}

		let fetchTasksStats = async () => {
			setTasksStatsLoading(true);
			try {
				let response = await TasksService.getTasksStatisticsByOwnerId(user?.id);
				return response?.data;
			} catch (e) {
				console.error(e);
			}
		}

		fetchTasks()
		.then(response => {
			setCurrentUserTasks(response);
			setLoadingCurrentUserTasks(false);
		})

		fetchTasksStats()
			.then(response => {
				setTaskStats(response);
				setTasksStatsLoading(false);
			})

	}, [user]);

	return (
		<div>
			<Header
				title="Task"
				rightActionComponent={
					<Button
						onClick={handleOpenDrawer}
						icon={<PlusOutlined />}
					>
						Add tasks
					</Button>
				}
			/>

			<div className="mt-3">
				<div className="row g-3">
					<div className="col-6 col-md-3">
						<DashboardInfoCard
							loading={tasksStatsLoading}
							classes="border-0 bg-dark text-white"
							title="Not started"
							value={taskStats?.not_started}
						/>
					</div>
					<div className="col-6 col-md-3">
						<DashboardInfoCard
							loading={tasksStatsLoading}
							classes="border-0 bg-primary text-white"
							title="In progress"
							value={taskStats?.in_progress}
						/>
					</div>
					<div className="col-6 col-md-3">
						<DashboardInfoCard
							classes="border-0 bg-warning text-white"
							loading={tasksStatsLoading}
							title="On hold"
							value={taskStats?.on_hold}
						/>
					</div>
					<div className="col-6 col-md-3">
						<DashboardInfoCard
							classes="border-0 bg-danger text-white"
							loading={tasksStatsLoading}
							title="Due"
							value={taskStats?.due}
						/>
					</div>
				</div>
			</div>

			<div className="mt-3">
				<Table
					dataSource={currentUserTasks}
					loading={loadingCurrentUserTasks}
					columns={taskTableColumns}
					scroll={{ x: 1 }}
					pagination={false}
				/>
			</div>

			<Drawer
				title="Create new task"
				open={isNewTaskDrawerOpen}
				onClose={() => setIsNewTaskDrawerOpen(false)}
				size="large"
				destroyOnClose
				mask
			>
				{
					drawerFormDepsLoading ?
						<div className="d-flex justify-content-center align-items-center py-5">
							<Spin/>
						</div> :
						(
							<Form
								layout="vertical"
								onFinish={handleSubmit}
							>
								<Form.Item
									rules={[{required: true}]}
									name="name"
									label="Title"
								>
									<Input placeholder="Title" size="large" />
								</Form.Item>
								<Form.Item
									rules={[{required: true}]}
									name="description"
									label="Description"
								>
									<Input.TextArea placeholder="Description" size="large" />
								</Form.Item>
								<Form.Item
									rules={[{required: true}]}
									name="start_date"
									label="Start date"
								>
									<DatePicker
										placeholder="Start date"
										size="large"
										className="w-100"
									/>
								</Form.Item>
								<Form.Item
									rules={[{required: true}]}
									name="due_date"
									label="Due date"
								>
									<DatePicker placeholder="Due date" size="large" className="w-100"/>
								</Form.Item>
								<Form.Item
									rules={[{required: true}]}
									name="priority"
									label="Priority"
								>
									<Select
										options={[
											{
												label: "Low",
												value: "LOW",
											},
											{
												label: "Medium",
												value: "MEDIUM",
											},
											{
												label: "High",
												value: "HIGH",
											}
										]}
										placeholder="Priority"
										size="large"
									/>
								</Form.Item>
								<Form.Item
									rules={[{required: true}]}
									name="project"
									label="Project"
								>
									<Select
										options={
											projects?.map((project) => ({
												label: project.name,
												value: project.id,
											}))
										}
										onChange={handleChangeProject}
										placeholder="Project"
										size="large"
									/>
								</Form.Item>
								<Form.Item
									name="created_by"
									label="Created by"
									hidden
									initialValue={user?.id}
								>
									<Input />
								</Form.Item>
								<Form.Item
									rules={[{required: true}]}
									name="assigned_to"
									label="Assigned to"
								>
									<Select
										options={
											members?.map((member) => ({
												label: `${member?.first_name} ${member?.last_name} ${member?.id === user?.id ? '(you)' : '' }`,
												value: member?.id
											}))
										}
										placeholder="Assigned to"
										size="large"
									/>
								</Form.Item>

								<Form.Item
									rules={[{required: false}]}
									name="dependencies"
									label="Dependencies(tasks)"
								>
									<Select
										options={
											tasks?.map((task) => ({
												label: task.name,
												value: task?.id
											}))
										}
										placeholder="Dependencies(tasks)"
										size="large"
										mode="multiple"
									/>
								</Form.Item>

								<Button
									loading={createTaskBtnLoading}
									disabled={createTaskBtnLoading}
									type="primary"
									size="large"
									htmlType="submit"
								>
									Create
								</Button>
							</Form>
						)
				}
			</Drawer>
		</div>
	);
};

export default Task;
