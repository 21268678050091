const authHeader = () => {
	let accessToken = localStorage.getItem("access_token");

	if (accessToken !== null || accessToken !== undefined) {
		return JSON.parse(accessToken);
	} else {
		return "";
	}
}

export default authHeader;
