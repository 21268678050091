import Header from "../../../components/Header";

const Settings = () => {

	return (
		<div>
			<Header title="Settings"/>
			<div className="container-fluid">

			</div>
		</div>
	)
}

export default Settings;
