import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";

const Logout = () => {
	let navigate = useNavigate();

	useEffect(() => {
		localStorage.clear();
		window.location.reload();
		navigate("/")
	}, [navigate]);
	return (
		<div>
			Logging out...
		</div>
	);
};

export default Logout;
