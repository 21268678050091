import React from 'react';
import "../Assets/css/ProjectsCard.css"
import dayjs from "dayjs";
import ProjectStatusBadge from "./ProjectStatusBadge";

function ProjectCard({ project }) {
	return (
		<div className="project-card border">
			<div className="project-card-header">
				<h6 className="m-0">{project.name}</h6>
				<ProjectStatusBadge status={project.status} />
			</div>
			<p className="project-goal m-0">{project.goal}</p>
			<p className="project-purpose small m-0">{project.purpose}</p>

			<div className="project-dates">
				<span>Start Date: {project.start_date}</span>
				<span>End Date: {project.end_date}</span>
			</div>

			<div className="project-meta">
				<span>Division: {project.division}</span>
				<span>Created By: {project.created_by}</span>
			</div>

			<div className="project-footer">
				<span>Last Updated: {dayjs(project.updated_at).format("YYYY-MM-DD")}</span>
			</div>
		</div>
	);
}

export default ProjectCard;
