import {useState} from "react";

const useToken = () => {
	let [accessToken, setAccessToken] = useState(localStorage.getItem("access_token"));

	let setToken = (key, value) => {
		localStorage.setItem(key, JSON.stringify(value));
		setAccessToken(value);
	}

	let removeToken = () => {
		setAccessToken(null);
		localStorage.removeItem("access_token");
	}

	return {accessToken, setToken, removeToken}
}

export default useToken;
