import {useRef, useState} from "react";
import {message, Upload, Image, Form, Input, Button} from "antd";
import {ArrowRightOutlined, PlusOutlined} from "@ant-design/icons";
import SignatureCanvas from 'react-signature-canvas'
import Users from "../../services/users.service";
import useAuth from "../../hooks/useAuth";
import {useNavigate} from "react-router-dom";

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

const CompleteMinimumProfile = () => {
	let {user} = useAuth();
	let [fileList, setFileList] = useState([]);
	let [previewOpen, setPreviewOpen] = useState(false);
	let [previewImage, setPreviewImage] = useState("");
	let [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
	let [submitBtnLoading, setSubmitBtnLoading] = useState(false);
	let [location, setLocation] = useState("");
	let [phone, setPhone] = useState("");
	let navigate = useNavigate();

	const sigPad = useRef(null);

	const clear = () => {
		sigPad.current.clear();
	};

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
	};

	const props = {
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
		beforeUpload: (file) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				setFileList((prev) => [{ url: reader.result }]);
			};
			return false;
		},
		fileList,
		listType: "picture-circle",
		accept: "image/*",
	};

	const uploadButton = (
		<button
			style={{
				border: 0,
				background: 'none',
			}}
			type="button"
		>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				Upload
			</div>
		</button>
	);

	let handleSubmit = async () => {
		if (fileList.length <= 0) {
			message.error("Please select your profile picture.");
			return;
		}

		if (phone === "" || phone.length <= 0) {
			message.error("Please enter a valid phone.");
			return;
		}

		if (location === "" || location.length <= 0) {
			message.error("Please enter a valid location.");
			return;
		}

		if (sigPad.current.isEmpty()) {
			message.error("Please enter a valid signature.");
			return;
		}

		setSubmitBtnDisabled(true);
		setSubmitBtnLoading(true);

		let formData = new FormData();
		formData.append('picture', fileList[0].url);
		formData.append('signature', sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
		formData.append('phone_number', phone);
		formData.append('location', location);
		formData.append('is_minimum_profile_complete', true);

		if (user?.was_invited) {
			formData.append('is_onboarded', true);
		} else {
			formData.append('is_onboarded', false);
		}

		try {
			let response = await Users.minimalUpdateUser(user.id, formData);
			if (response.status === 200) {
				if (user?.was_invited) {
					navigate("/dashboard");
				}
				window.location.reload();
			}
		} catch (e) {
			setSubmitBtnDisabled(false);
			setSubmitBtnLoading(false);
			message.error("An error occurred. Please try again later.");
		}
	}

	return (
		<div className="container py-3">
			<h4>Complete your profile</h4>

			<div className="my-3">
				<span className="small text-muted">
					Please select profile picture
				</span>
				<Upload {...props} onPreview={handlePreview} maxCount={1}>
					{fileList.length >= 8 ? null : uploadButton}
				</Upload>

				<div>
					{previewImage && (
						<Image
							wrapperStyle={{
								display: 'none',
							}}
							preview={{
								visible: previewOpen,
								onVisibleChange: (visible) => setPreviewOpen(visible),
								afterOpenChange: (visible) => !visible && setPreviewImage(''),
							}}
							src={previewImage}
						/>
					)}
				</div>

				<div className="col-md-6 my-3">
					<Form
						layout="vertical"
					>
						<Form.Item
							name="phoneNumber"
							label="Phone number"
						>
							<Input
								size="large"
								placeholder="Phone number"
								onChange={e => setPhone(e.target.value)}
							/>
						</Form.Item>
						<Form.Item
							name="location"
							label="Location"
						>
							<Input
								size="large"
								placeholder="Location"
								onChange={e => setLocation(e.target.value)}
							/>
						</Form.Item>
					</Form>

					<div>
						<p className="small">
							Signature
						</p>
						<div>
							<SignatureCanvas
								ref={sigPad}
								penColor='black'
								canvasProps={{width: 250, height: 130, className: 'border'}}
								backgroundColor='rgba(255,0,0,0)'
							/>
						</div>
						<Button type="primary" size="small" danger onClick={clear}>Clear</Button>
					</div>
				</div>

				<div className="col-md-6 d-flex justify-content-end">
					<Button
						onClick={handleSubmit}
						loading={submitBtnLoading}
						disabled={submitBtnDisabled}
						size="large"
						type="primary"
						className="px-5"
						icon={<ArrowRightOutlined />}
						iconPosition="end"
					>
						Next
					</Button>
				</div>
			</div>
		</div>
	)
}

export default CompleteMinimumProfile;
