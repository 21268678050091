import httpCommon from "../http-common";

class ProjectsService {
	create(data) {
		return httpCommon.post('projects/', data);
	}

	getByMemberId(id) {
		return httpCommon.get(`projects/getByMemberId/${id}`);
	}

	getNumberOfProjectsByMemberId(id) {
		return httpCommon.get(`projects/getNumberOfProjectsByMemberId/${id}`);
	}

	getMembersByProjectId(id) {
		return httpCommon.get(`projects/getMembersByProjectId/${id}`);
	}
}

export default new ProjectsService();
