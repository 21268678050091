import React from 'react';
import {Spin} from "antd";

const DashboardInfoCard = ({title, value, loading, classes}) => {
	return (
		<div className={`rounded-1 border shadow-sm p-3 ${classes}`} style={{height: 100}}>
			<span className="d-block">{title}</span>
			{
				loading ?
					<Spin/> :
					<h1>{value}</h1>
			}
		</div>
	);
};

export default DashboardInfoCard;
