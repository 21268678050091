const Notifications = () => {
	return (
		<div
			style={{width: 350}}
		>
			<h4>Notifications</h4>
		</div>
	)
}

export default Notifications;
