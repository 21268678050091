import useAuth from "../../hooks/useAuth";
import EmailVerification from "./EmailVerification";
import CompleteMinimumProfile from "./CompleteMinimumProfile";
import {useState} from "react";
import {Button, Checkbox, Divider, Form, Input, message, Select, Radio} from "antd";
import {Link, Navigate, useNavigate} from "react-router-dom";
import Users from "../../services/users.service";
import {INDUSTRY_TYPES} from "../../constants";
import WorkspacesService from "../../services/workspaces.service";

const UserOnboarding = () => {
	let {user} = useAuth();
	let [workspace, setWorkspace] = useState("INDIVIDUAL");
	let [accepted, setAccepted] = useState(false);
	let [loading, setLoading] = useState(false);
	let navigate = useNavigate();

	let handleSubmit = async (values) => {
		let data = {
			workspace_type: workspace,
			minimum_team_size: values.team_size.min,
			maximum_team_size: values.team_size.max,
			...values
		}
		setLoading(true);
		try {
			let newWorkspace = await WorkspacesService.create(data);
			if (newWorkspace.status === 201) {
				let response = await Users.completeSignup(user.id, {
					workspace: newWorkspace.data.id,
				})
				if (response && response.status === 200) {
					navigate("/dashboard");
					window.location.reload();
				}
			}
		} catch (e) {
			setLoading(false);
			message.error("Failed to complete signup. Please try again later.");
		}
	}

	const onChangeCheckbox = (e) => {
		setAccepted(!accepted)
	};

	if (!user?.is_email_verified) {
		return <EmailVerification />
	}

	if (!user?.is_minimum_profile_complete) {
		return <CompleteMinimumProfile />
	}

	if (user?.is_onboarded) {
		return <Navigate to="/dashboard" replace={true} />
	}

	return (
		<div className="container py-3">
			<h4 className="text-center">Choose workspace type</h4>
			<div className="row mt-3 g-2">
				<div className="col-md-6" onClick={() => setWorkspace("INDIVIDUAL")}>
					<div className={`border rounded-1 ${workspace === "INDIVIDUAL" && 'border-primary'} p-3`}>
						<h6 className="text-center">Individual</h6>
						<p className="small text-muted">
							Suitable for individuals who need tools for personal projects, freelance work, or small-scale operations.
						</p>
					</div>
				</div>

				<div className="col-md-6" onClick={() => setWorkspace("COMPANY")}>
					<div className={`border rounded-1 ${workspace === "COMPANY" && 'border-primary'} p-3`}>
						<h6 className="text-center">Company</h6>
						<p className="small text-muted">
							Suitable for organizations or teams that requires more robust tools to manage multiple users, projects, and workflows.
						</p>
					</div>
				</div>
			</div>

			<div className="mt-3">
				<Divider type="horizontal" />
				<h5>Workspace information</h5>
				<Form
					onFinish={handleSubmit}
					layout="vertical"
				>
					<div className="row">
						<div className="col-md-6">
							<Form.Item
								rules={[{required: true}]}
								name="name"
								label="Name"
								help="The name of your organization if business workspace or your name if individual workspace."
							>
								<Input
									placeholder="Name"
									size="large"
								/>
							</Form.Item>
							<Form.Item
								name="industry"
								label="Industry"
								rules={[{required: true}]}
							>
								<Select
									placeholder="Name"
									size="large"
									options={INDUSTRY_TYPES}
								/>
							</Form.Item>
							<Form.Item
								rules={[{required: true}]}
								name="team_size"
								label="Team size"
								help="Please choose INDIVIDUAL workspace if you are self employed."
							>
								<Radio.Group
									buttonStyle="solid"
									size="large"
								>
									<Radio.Button value={{min: 1, max: 1}}>Self employed</Radio.Button>
									<Radio.Button value={{min: 1, max: 10}}>1 - 10</Radio.Button>
									<Radio.Button value={{min: 11, max: 50}}>11 - 50</Radio.Button>
									<Radio.Button value={{min: 51, max: 200}}>51 - 200</Radio.Button>
								</Radio.Group>
							</Form.Item>
							<Form.Item
								rules={[{required: true}]}
								name="description"
								label="Description"
								help="A brief description about your workspace."
							>
								<Input.TextArea
									placeholder="Description"
									autoSize={{minRows: 5, maxRows: 5}}
									maxLength={100}
									showCount
								/>
							</Form.Item>
						</div>
						<div className="col-md-6">
							<Form.Item
								rules={[{required: true}]}
								name="workspace_identifier"
								label="Workspace identifier"
								help="This are letters that are added before your team member's unique number e.g EMP1001"
							>
								<Input size="large" placeholder="Workspace identifier"/>
							</Form.Item>
							<Form.Item
								name="email"
								label="Email"
							>
								<Input size="large" placeholder="Email"/>
							</Form.Item>
							<Form.Item
								name="phoneNumber"
								label="Phone number"
								help="Include the country code e.g. +263771900876"
							>
								<Input size="large" placeholder="Phone number"/>
							</Form.Item>
							<Form.Item
								name="website"
								label="Website"
								help="Begin the website with https://"
							>
								<Input size="large" placeholder="Website"/>
							</Form.Item>
						</div>
					</div>
					<Divider type="horizontal" />
					<div className="my-3">
						<Checkbox checked={accepted} onChange={onChangeCheckbox}>
							By completing signing up you agree with The Erector <Link to="/">terms of
							service</Link> and <Link to="/">privacy policy</Link>.
						</Checkbox>
					</div>

					<div className="my-3">
						<Button
							htmlType="submit"
							loading={loading}
							disabled={!accepted || loading}
							type="primary"
							size="large"
							className="px-5 rounded-1"
						>
							Complete signing up
						</Button>
					</div>
				</Form>
			</div>
		</div>
	)
}

export default UserOnboarding;
