import httpCommon from "../http-common";

class DivisionsService {
	create(data) {
		return httpCommon.post('divisions/', data);
	}

	listDivisionsByWorkspaceId(workspaceId) {
		return httpCommon.get(`divisions/listDivisionsByWorkspaceId/${workspaceId}`);
	}
}

export default new DivisionsService();
