import useAuth from "../hooks/useAuth";
import {Navigate, useLocation} from "react-router-dom";

const RequireAuth = ({ children }) => {
	let {isAuthenticated} = useAuth()
	let location = useLocation()

	if (isAuthenticated === false) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children;
}

export default RequireAuth;
