import DashboardInfoCard from "../../components/DashboardInfoCard";
import React, {useEffect, useState} from "react";
import UsersService from "../../services/users.service";
import useAuth from "../../hooks/useAuth";
import ProjectsService from "../../services/projects.service";
import TasksService from "../../services/tasks.service";

const Dashboard = () => {
	let {user} = useAuth();
	let [teamSize, setTeamSize] = useState(null);
	let [numberOfProjects, setNumberOfProjects] = useState(null);
	let [teamSizeLoading, setTeamSizeLoading] = useState(false);
	let [numberOfProjectsLoading, setNumberOfProjectsLoading] = useState(false);
	let [tasksStatsLoading, setTasksStatsLoading] = useState(false);
	let [taskStats, setTaskStats] = useState({});

	useEffect(() => {
		let fetchTeamSize = async () => {
			setTeamSizeLoading(true);
			try {
				let response = await UsersService.getTotalNumberByWorkspaceId(user?.workspace?.id)
				return response?.data?.team_size;
			} catch (e) {
				return null
			}
		}

		let fetchNumberOfProjects = async () => {
			setNumberOfProjectsLoading(true);
			try {
				let response = await ProjectsService.getNumberOfProjectsByMemberId(user?.id)
				return response?.data?.number_of_projects;
			} catch (e) {
				return null
			}
		}

		let fetchTasksStats = async () => {
			setTasksStatsLoading(true);
			try {
				let response = await TasksService.getTasksStatisticsByOwnerId(user?.id);
				return response?.data;
			} catch (e) {
				console.error(e);
			}
		}

		if (user) {
			fetchTeamSize()
				.then(response => {
					setTeamSize(response)
					setTeamSizeLoading(false);
				})
		}

		if (user) {
			fetchNumberOfProjects()
				.then(response => {
					setNumberOfProjects(response)
					setNumberOfProjectsLoading(false);
				})
		}

		fetchTasksStats()
			.then(response => {
				setTaskStats(response);
				setTasksStatsLoading(false);
			})

	}, [user]);

	return (
		<div className="pt-3">
			<div className="row g-3">
				<div className="col-6 col-md-3">
					<DashboardInfoCard loading={teamSizeLoading} title="Team size" value={teamSize}/>
				</div>
				<div className="col-6 col-md-3">
					<DashboardInfoCard loading={numberOfProjectsLoading} title="Projects" value={numberOfProjects}/>
				</div>
				<div className="col-6 col-md-3">
					<DashboardInfoCard
						loading={tasksStatsLoading}
						classes="border-0 bg-dark text-white"
						title="Tasks not started"
						value={taskStats?.not_started}
					/>
				</div>
				<div className="col-6 col-md-3">
					<DashboardInfoCard
						classes="border-0 bg-danger text-white"
						loading={tasksStatsLoading}
						title="Tasks due"
						value={taskStats?.due}
					/>
				</div>
			</div>
		</div>
	)
}

export default Dashboard;
