import "../../Assets/css/LandingPage.css";
import useAuth from "../../hooks/useAuth";
import {Navigate, useNavigate} from "react-router-dom"; // External CSS file for styling


const LandingPage = () => {
	let {isAuthenticated} = useAuth();
	let navigate = useNavigate();

	if (isAuthenticated) {
		return <Navigate to="/dashboard" replace />
	}

	return (
		<div className="landing-container">
			<nav className="navbar">
				<div className="logo">Erector</div>
				<div className="nav-buttons">
					<button className="nav-button" onClick={() => navigate("/login")}>Sign in</button>
					<button className="nav-button signup" disabled={true}>Sign up</button>
				</div>
			</nav>

			<div className="content">
				<h1>Let's build from here</h1>
				<p>The world’s leading project management software platform.</p>

				<form className="email-form">
					<input
						type="email"
						placeholder="you@company.com"
						aria-label="Email Address"
					/>
					<button type="submit" disabled={true}>Sign up</button>
				</form>

				<button className="trial-button">Start a free enterprise trial</button>
			</div>
		</div>
	);
};

export default LandingPage;
