import {Spin} from "antd";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const NotFound = () => {
	let navigate = useNavigate();

	useEffect(() => {
		navigate("/dashboard")
	})

	return <Spin fullscreen />
}

export default NotFound
