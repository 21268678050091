import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import {Button, Form, Input, message, Modal, Select, Space, Table} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons";
import useAuth from "../../hooks/useAuth";
import {DIVISION_CATEGORIES_OPTIONS} from "../../constants";
import UsersService from "../../services/users.service";
import DivisionsService from "../../services/divisions.service";
import {Link} from "react-router-dom";

const Divisions = () => {
	let {user} = useAuth();
	let [isNewDivisionModalOpen, setIsNewDivisionModalOpen] = useState(false);
	let [team, setTeam] = useState(null);
	let [loadingDivisions, setLoadingDivisions] = useState(false);
	let [divisions, setDivisions] = useState([]);
	let [loading, setLoading] = useState(false);

	let DIVISIONS_TABLE_CATEGORIES_OPTIONS = [
		{
			title: "Division name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Division category",
			dataIndex: "division_type",
			key: "division_type",
		},
		{
			title: "Number of members",
			dataIndex: "number_of_members",
			key: "number_of_members",
		},
		{
			title: "Head",
			dataIndex: "head",
			key: "head",
			render: (text, record) => (
				<Link to="/">
					{record?.head?.name}
				</Link>
			)
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			render: (text, record) => (
				<Space direction="horizontal">
					<Button
						size="small"
						icon={<EyeOutlined style={{color: "white"}} />}
						className="bg-primary border-primary"
						disabled
					/>
					<Button
						size="small"
						icon={<EditOutlined style={{color: "white"}} />}
						className="bg-warning border-warning"
						disabled
					/>
					<Button
						size="small"
						icon={<DeleteOutlined />}
						danger
						disabled/>
				</Space>
			)
		}
	]

	let createDivision = async (values) => {
		setLoading(true);

		try {
			let response = await DivisionsService.create(values);

			if (response.status === 201) {
				setDivisions(prevState => [...prevState, response?.data]);
				message.success("Division created successfully.");
				setIsNewDivisionModalOpen(false);
				setLoading(false);
			}
		} catch (e) {
			message.error("An error occurred.");
			setLoading(false);
		}
	}

	useEffect(() => {
		let fetchTeamMembers = async () => {
			try {
				let response = await UsersService.getByWorkspaceId(user?.workspace?.id);
				return response?.data;
			} catch (e) {
				return []
			}
		}

		let fetchDivisions = async () => {
			setLoadingDivisions(true);
			try {
				let response = await DivisionsService.listDivisionsByWorkspaceId(user?.workspace?.id)
				return response.data;
			} catch (e) {
				return null
			}
		}

		if (user) {
			fetchTeamMembers()
				.then(response => {
					setTeam(response);
				})
		}

		if (user) {
			fetchDivisions()
				.then(response => {
					setDivisions(response)
					setLoadingDivisions(false);
				})
		}

	}, [user])

	return (
		<div>
			<Header
				title="Divisions"
				rightActionComponent={
					<Button
						onClick={() => setIsNewDivisionModalOpen(!isNewDivisionModalOpen)}
						icon={<PlusOutlined />}
					>
						Add division
					</Button>
				}
			/>

			<div className="mt-3">
				<Table
					columns={DIVISIONS_TABLE_CATEGORIES_OPTIONS}
					dataSource={divisions}
					loading={loadingDivisions}
					scroll={{ x: 1 }}
					pagination={false}
				/>
			</div>

			<Modal
				title="Create division"
				okButtonProps={{
					className: 'd-none'
				}}
				cancelButtonProps={{
					className: 'd-none'
				}}
				open={isNewDivisionModalOpen}
				onCancel={() => setIsNewDivisionModalOpen(false)}
				maskClosable={false}
				closable
				destroyOnClose
			>
				<Form
					layout="vertical"
					name="createDivision"
					onFinish={createDivision}
				>
					<Form.Item
						rules={[{required: true, message: "Division name is required."}]}
						name="name"
						label="Division name"
					>
						<Input
							placeholder="Division name"
							size="large"
						/>
					</Form.Item>
					<Form.Item
						rules={[{required: true, message: "Division type is required."}]}
						name="division_type"
						label="Division category"
					>
						<Select
							options={DIVISION_CATEGORIES_OPTIONS}
							placeholder="Division category"
							size="large"
						/>
					</Form.Item>
					<Form.Item
						hidden
						name="workspace"
						initialValue={user?.workspace?.id}
					>
						<Input/>
					</Form.Item>
					<Form.Item
						name="head_of_division"
						label="Head of division"
					>
						<Select
							options={team?.map(member => ({
								value: member.id,
								label: `${member?.first_name} ${member?.last_name}`,
							}))}
							placeholder="Head of division"
							size="large"
						/>
					</Form.Item>

					<Form.Item>
						<Button
							loading={loading}
							size="large"
							type="primary"
							htmlType="submit"
							block
						>
							Add
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default Divisions;
