import {Route, Routes} from "react-router-dom";
import Login from "./pages/auth/Login/Login";
import Register from "./pages/auth/Register/Register";
import LandingPage from "./pages/public/LandingPage";
import Root from "./layouts/Root";
import RequireAuth from "./components/RequireAuth";
import Dashboard from "./pages/protected/Dashboard";
import UserOnboarding from "./pages/protected/UserOnboarding";
import useAuth from "./hooks/useAuth";
import NotFound from "./pages/errors/RouteNotFound";
import Settings from "./pages/protected/settings/Settings";
import Team from "./pages/protected/Team";
import NewTeamMemberPasswordReset from "./pages/protected/NewTeamMemberPasswordReset";
import Divisions from "./pages/protected/Divisions";
import Projects from "./pages/protected/Projects";
import Logout from "./pages/auth/Logout/Logout";
import Task from "./pages/protected/Task";

const AppEntry = () => {
	let {isAuthenticated} = useAuth();

	return (
		<Routes>
			<Route index element={<LandingPage />} />
			{
				isAuthenticated === false && (
					<>
						<Route path="/login" element={<Login />} />
						<Route path="/signup" element={<Register />} />
						<Route path="/reset-password" element={<NewTeamMemberPasswordReset />} />
					</>
				)
			}
			<Route element={<Root />}>
				<Route path="/dashboard" element={<RequireAuth><Dashboard/></RequireAuth>}/>
				<Route path="/onboarding" element={<RequireAuth><UserOnboarding/></RequireAuth>}/>
				<Route path="/settings" element={<RequireAuth><Settings/></RequireAuth>}/>
				<Route path="/team" element={<RequireAuth><Team/></RequireAuth>}/>
				<Route path="/divisions" element={<RequireAuth><Divisions/></RequireAuth>}/>
				<Route path="/projects" element={<RequireAuth><Projects/></RequireAuth>}/>
				<Route path="/tasks" element={<RequireAuth><Task/></RequireAuth>}/>
			</Route>
			<Route path="/logout" element={<RequireAuth><Logout/></RequireAuth>}/>
			<Route path="*" element={<NotFound />}/>
		</Routes>
	)
}

export default AppEntry;
