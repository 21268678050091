import useAuth from "../../hooks/useAuth";
import {Button, Image, Input, message} from "antd";
import logo from "../../Assets/Images/logo.png";
import {useState} from "react";
import Users from "../../services/users.service";

const EmailVerification = () => {
	let {user} = useAuth();
	let [loading, setLoading] = useState(false);
	let [disabled, setDisabled] = useState(false);
	let [requested, setRequested] = useState(false);
	let [otpInputDisabled, setOtpInputDisabled] = useState(false);

	let handleRequestVerificationCode = async () => {
		setLoading(true);
		setDisabled(true)

		try {
			let response = await Users.sendEmailVerificationCode()
			if (response.status === 200) {
				setRequested(true);
			}
		} catch (e) {
			setLoading(false);
			setDisabled(false)
			message.error("An error occurred while sending email verification code.");
		}
	}

	let handleSubmit = async (value) => {
		setOtpInputDisabled(true);

		try {
			let response = await Users.verifyEmailVerificationCode({
				verification_code: value
			})
			if (response.status === 200) {
				window.location.reload()
			}
		} catch (e) {
			setOtpInputDisabled(false);
			message.error("An error occurred while verifying email verification code. Please try again.");
		}
	}

	return (
		<div className="vh-100 d-flex justify-content-center align-items-center">
			<div className="text-center p-5 m-3 shadow-lg" style={{maxWidth: 400}}>
				<div className="d-flex justify-content-center align-items-center mb-3">
					<Image src={logo} preview={false} height={100} width={100} />
				</div>

				{
					!requested ? (
						<>
							<p>Request email verification code to <span className="fw-bold">{user?.email}</span></p>

							<Button
								onClick={handleRequestVerificationCode}
								loading={loading}
								disabled={disabled}
								style={{height: 54}}
								className="rounded-1"
								size="large"
								block
							>
								Request
							</Button>
						</>
					) : (
						<>
							<p>Please enter verification code sent to <span className="fw-bold">{user?.email}</span></p>

							<Input.OTP
								size="large"
								disabled={otpInputDisabled}
								onChange={handleSubmit}
							/>
						</>
					)
				}
			</div>
		</div>
	)
}

export default EmailVerification
