import {auth} from "../http-common";

class AuthService {
	socialLogin(data) {
		return auth.postForm(`convert-token`, data)
	}

	normalLogin(data) {
		return auth.post('token', data)
	}
}

export default new AuthService();
