import httpCommon from "../http-common";

class TasksService {
	create(data) {
		return httpCommon.post('tasks/', data)
	}

	getTasksByProjectId(id) {
		return httpCommon.get(`tasks/getTasksByProjectId/${id}`);
	}

	getTasksByOwnerId(id) {
		return httpCommon.get(`tasks/getTasksByOwnerId/${id}`);
	}

	getTasksStatisticsByOwnerId(id) {
		return httpCommon.get(`tasks/getTasksStatisticsByOwnerId/${id}`);
	}
}

export default new TasksService();
