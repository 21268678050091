import logo from "../../Assets/Images/logo.png"
import {Button, Form, Image, Input, message} from "antd";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import UsersService from "../../services/users.service";
import useToken from "../../hooks/useToken";

const NewTeamMemberPasswordReset = () => {
	let [searchParams] = useSearchParams();
	let navigate = useNavigate();
	let [password, setPassword] = useState("");
	let [confirmPassword, setConfirmPassword] = useState("");
	let [resetPwdBtnDisabled, setResetPwdBtnDisabled] = useState(true);
	let [resetBtnLoading, setResetBtnLoading] = useState(false);
	let {setToken} = useToken();

	let handleResetPassword = async (values) => {
		if (values.password !== values.confirmPassword) {
			message.error("Your passwords do not match.");
			return;
		}

		setResetBtnLoading(true);
		setResetPwdBtnDisabled(true);
		try {
			let response = await UsersService.newTeamMemberPasswordReset(
				{
					new_password: values.password,
					id: searchParams.get("i")
				},
				searchParams.get("t")
			)
			if (response.status === 200) {
				setToken('access_token', searchParams.get("t"));
				window.location.reload();
				navigate("/onboarding");
			}
		} catch (e) {
			setResetBtnLoading(true);
			setResetPwdBtnDisabled(true);
			message.error("An error occurred while trying to reset password. Please contact administrator.");
		}
	}

	useEffect(() => {
		if (!searchParams.has("t") || !searchParams.has("i")) {
			navigate("/")
		}
	}, [navigate, searchParams]);

	useEffect(() => {
		if (password === "" || confirmPassword === "") {
			setResetPwdBtnDisabled(true);
		} else {
			setResetPwdBtnDisabled(false);
		}
	}, [confirmPassword, password])

	return (
		<div className="container d-flex justify-content-center align-items-center mt-5">
			<div>
				<Image src={logo} alt="Erector Logo" height={50} width={50} preview={false}/>
				<h5 className="mt-3">Password reset</h5>
				<p className="small text-muted">Welcome to Erector, please enter a new password to start using your account.</p>

				<Form
					layout="vertical"
					name="resetPassword"
					onFinish={handleResetPassword}
				>
					<Form.Item
						name="password"
						label="Password"
						rules={[{required: true, message: "Please enter your password."}]}
					>
						<Input.Password
							onChange={e => setPassword(e.target.value)}
							size="large"
							placeholder="Password"
							className="rounded-1"
						/>
					</Form.Item>
					<Form.Item
						label="Confirm password"
						name="confirmPassword"
						rules={[{required: true, message: "Please repeat your password."}, ]}
					>
						<Input.Password
							onChange={e => setConfirmPassword(e.target.value)}
							size="large"
							placeholder="Confirm password"
							className="rounded-1"
						/>
					</Form.Item>
					<Button
						loading={resetBtnLoading}
						htmlType="submit"
						disabled={resetPwdBtnDisabled}
						className="rounded-1 mt-3"
						type="primary"
						size="large"
						block
					>
						Reset password
					</Button>
				</Form>

			</div>
		</div>
	)
};

export default NewTeamMemberPasswordReset;
