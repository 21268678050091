import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import {useState} from "react";
import {Avatar, Badge, Button, Dropdown, Image, Layout, Menu, Popover, Space, Spin} from "antd";
import {
	BarChartOutlined,
	BellOutlined,
	DashboardOutlined,
	DollarOutlined,
	ExportOutlined,
	FileOutlined,
	FolderOpenOutlined,
	HourglassOutlined,
	LockOutlined,
	MenuOutlined,
	MessageOutlined,
	NotificationOutlined,
	PartitionOutlined,
	PoweroffOutlined,
	ProductOutlined,
	ReadOutlined,
	ScheduleOutlined,
	SettingOutlined,
	TeamOutlined,
	UserOutlined,
} from "@ant-design/icons";
import theErectorLogo from "../Assets/Images/logo.png";
import Notifications from "../components/Notifications";
import "./Root.css"

const Root = () => {
	let {user, isAuthenticated} = useAuth();
	let [collapsed, setCollapsed] = useState(false);
	let navigate = useNavigate();
	let location = useLocation();
	let isLargeDevice = Number(window.innerWidth) >= 992

	let getSelectedKey = () => {
		if (location.pathname === "/dashboard") {
			return ["Dashboard"]
		} else if (location.pathname === "/projects") {
			return ["Projects"]
		} else if (location.pathname === "/finance") {
			return ["Finance"]
		} else if (location.pathname === "/announcements") {
			return ["Announcements"]
		} else if (location.pathname === "/communication") {
			return ["Communication"]
		} else if (location.pathname === "/documents") {
			return ["Documents"]
		} else if (location.pathname === "/team") {
			return ["Team"]
		} else if (location.pathname === "/training-development") {
			return ["TrainingDevelopment"]
		} else if (location.pathname === "/analytics") {
			return ["Analytics"]
		} else if (location.pathname === "/frameworks") {
			return ["Frameworks"]
		} else if (location.pathname === "/settings") {
			return ["Settings"]
		} else if (location.pathname === "/divisions") {
			return ["Divisions"]
		} else if (location.pathname === "/tasks") {
			return ["Tasks"]
		}
	}

	const siderStyle = {
		overflow: 'auto',
		height: '100vh',
		position: 'fixed',
		insetInlineStart: 0,
		top: 0,
		bottom: 0,
		scrollbarWidth: 'thin',
		scrollbarColor: 'unset',
		zIndex: 9999,
	};

	let items = [
		{
			key: "Dashboard",
			icon: <DashboardOutlined />,
			label: "Dashboard",
			onClick: () => {
				navigate("/dashboard");
				if (!isLargeDevice) {
					handleChangeSiderState()
				}
			}
		},
		{
			key: "Tasks",
			icon: <HourglassOutlined />,
			label: "Tasks",
			onClick: () => {
				navigate("/tasks");
				if (!isLargeDevice) {
					handleChangeSiderState()
				}
			}
		},
		{
			key: "Projects",
			icon: <FolderOpenOutlined />,
			label: "Projects",
			onClick: () => {
				navigate("/projects");
				if (!isLargeDevice) {
					handleChangeSiderState()
				}
			}
		},
		{
			key: "Blueprints",
			icon: <ProductOutlined />,
			label: "Blueprints",
			onClick: () => {
				navigate("/blueprints");
				if (!isLargeDevice) {
					handleChangeSiderState()
				}
			}
		},
		{
			type: "divider"
		},
		{
			key: "Finance",
			icon: <DollarOutlined />,
			label: "Finance",
			onClick: () => {
				navigate("/finance");
				if (!isLargeDevice) {
					handleChangeSiderState()
				}
			}
		},
		{
			key: "Divisions",
			icon: <PartitionOutlined />,
			label: "Divisions",
			onClick: () => {
				navigate("/divisions");
				if (!isLargeDevice) {
					handleChangeSiderState()
				}
			}
		},
		{
			key: "Team",
			icon: <TeamOutlined />,
			label: "Team",
			onClick: () => {
				navigate("/team");
				if (!isLargeDevice) {
					handleChangeSiderState()
				}
			}
		},
		{
			type: "divider"
		},
		{
			key: "Announcements",
			icon: <NotificationOutlined />,
			label: "Announcements",
			onClick: () => {
				navigate("/announcements");
				if (!isLargeDevice) {
					handleChangeSiderState()
				}
			}
		},
		{
			key: "Communication",
			icon: <MessageOutlined />,
			label: "Communication",
			onClick: () => {
				navigate("/communication");
				if (!isLargeDevice) {
					handleChangeSiderState()
				}
			}
		},
		{
			key: "Documents",
			icon: <FileOutlined />,
			label: "Documents",
			onClick: () => {
				navigate("/documents");
				if (!isLargeDevice) {
					handleChangeSiderState()
				}
			}
		},
		{
			key: "TrainingDevelopment",
			icon: <ReadOutlined />,
			label: "Training & Development",
			onClick: () => {
				navigate("/training-development");
				if (!isLargeDevice) {
					handleChangeSiderState()
				}
			}
		},
		{
			key: "Analytics",
			icon: <BarChartOutlined />,
			label: "Analytics",
			onClick: () => {
				navigate("/analytics");
				if (!isLargeDevice) {
					handleChangeSiderState()
				}
			}
		},
		{
			key: "Frameworks",
			icon: <ScheduleOutlined />,
			label: "Frameworks",
			onClick: () => {
				navigate("/frameworks");
				if (!isLargeDevice) {
					handleChangeSiderState()
				}
			}
		},
		{
			key: "Settings",
			icon: <SettingOutlined />,
			label: "Settings",
			onClick: () => {
				navigate("/settings");
				if (!isLargeDevice) {
					handleChangeSiderState()
				}
			}
		}
	]

	const items1 = [
		{
			key: '1',
			label: 'Profile',
			icon: <UserOutlined />
		},
		{
			type: 'divider',
		},
		{
			key: '2',
			label: (
				<span className="d-flex justify-content-between">
					<span>Manage account</span>
					<ExportOutlined />
				</span>
			),
			icon: <LockOutlined/>
		},
		{
			key: '3',
			label: 'Settings',
			icon: <SettingOutlined />
		},
		{
			type: 'divider',
		},
		{
			key: '4',
			label: 'Logout',
			onClick: () => navigate("/logout"),
			icon: <PoweroffOutlined />
		},
	];

	let handleChangeSiderState = () => {
		setCollapsed(!collapsed)
	}

	if (user) {
		return (
			<>
				{
					user?.is_onboarded ? (
							<Layout hasSider>
								<Layout.Sider
									style={siderStyle}
									collapsible
									collapsed={collapsed}
									onCollapse={(value) => setCollapsed(value)}
									collapsedWidth={0}
									trigger={null}
									width={250}
									breakpoint="lg"
								>
									<div
										className="p-3 position-sticky top-0 border-bottom border-opacity-25 border-white mb-3"
										style={{zIndex: 99, backgroundColor: "#001529"}}
									>
										<div className="d-flex justify-content-between align-items-center">
											<Image src={theErectorLogo} width={20} height={20} preview={false} />
											<MenuOutlined
												className="text-light d-block d-lg-none"
												style={{fontSize: 22}}
												onClick={handleChangeSiderState}
											/>
										</div>
									</div>
									<Menu theme="dark" defaultSelectedKeys={getSelectedKey()} mode="inline" items={items} />
								</Layout.Sider>
								<Layout
									className={`bg-white ${!collapsed && "content-layout"}`}
								>
									<Layout.Content
										className="container vh-100 overflow-y-scroll"
									>
										<div
											className="w-100 py-3 position-sticky top-0 bg-white d-flex justify-content-between align-items-center"
											style={{zIndex: 99}}
										>
											<div className="d-flex justify-content-between align-items-center gap-3">
												<MenuOutlined
													style={{fontSize: 22}}
													onClick={handleChangeSiderState}
												/>
												<h5 className="m-0">{user?.workspace?.name}</h5>
											</div>

											<div className="d-flex justify-content-between align-items-center gap-2">
												<Popover
													arrow={false}
													content={<Notifications />}
													trigger="click"
													placement="bottomLeft"
												>
													<Button
														type="text"
														icon={<Badge dot size="small"><BellOutlined /></Badge>}
														shape="circle"
														size="small"
													/>
												</Popover>

												<Dropdown
													menu={{
														items: items1,
													}}
													overlayStyle={{width: 200}}
													placement="bottomLeft"
												>
													<a onClick={(e) => e.preventDefault()}>
														<Space>
															<Avatar
																src={user?.picture}
																icon={<UserOutlined />}
																size="small"
															/>
														</Space>
													</a>
												</Dropdown>
											</div>
										</div>
										<Outlet/>
									</Layout.Content>
								</Layout>
							</Layout>
						) :
						<Outlet />
				}
			</>
		)
	}

	if (!isAuthenticated) {
		return <Navigate to="/login" />;
	}

	return <Spin size="large" fullscreen />
}

export default Root
