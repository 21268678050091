import React from 'react';
import "../Assets/css/ProjectStatusBadge.css"

const ProjectStatusBadge = ({ status }) => {
	// eslint-disable-next-line default-case
	switch (status) {
		case "ACTIVE_STABLE":
			return <span className="project-status text-light bg-success">STABLE</span>
		case "ON_HOLD":
			return <span className="project-status bg-dark-subtle text-light border">ON HOLD</span>
		case "COMPLETED":
			return <span className="project-status bg-light border border-dark">COMPLETED</span>
		case "NOT_STARTED":
			return <span className="project-status bg-black border text-light">INITIALISING</span>
		case "ACTIVE_DANGER":
			return <span className="project-status text-light" style={{backgroundColor: "red"}}>DANGER</span>
		case "ACTIVE_URGENCY":
			return <span className="project-status text-light">URGENCY</span>
		case "ACTIVE_AFFLUENCE":
			return <span className="project-status text-light" style={{backgroundColor: "gold"}}>AFFLUENCE</span>
		case "ACTIVE_POWER":
			return <span className="project-status text-light" style={{backgroundColor: "darkblue"}}>POWER</span>
	}
};

export default ProjectStatusBadge;
