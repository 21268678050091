import httpCommon from "../http-common";

class RolesService {
	listRolesByWorkspaceId(workspaceId) {
		return httpCommon.get(`roles/listRolesByWorkspaceId/${workspaceId}`);
	}

	create(data) {
		return httpCommon.post(`roles/`, data);
	}
}

export default new RolesService();
