import ReactDOM from "react-dom/client";
import {GoogleOAuthProvider} from "@react-oauth/google";
import AppEntry from "./AppEntry";
import {BrowserRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'
import '../src/Assets/css/fonts.css'
import AuthProvider from "./context/auth";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<GoogleOAuthProvider clientId="435365897993-du7q02g8kjqv0hrr8pna04hfu9skv9pp.apps.googleusercontent.com">
		<BrowserRouter>
			<AuthProvider>
				<AppEntry/>
			</AuthProvider>
		</BrowserRouter>
	</GoogleOAuthProvider>
)
