import {Button} from "antd";
import {ReloadOutlined} from "@ant-design/icons";

const NetworkErrorPage = () => {
	return (
		<div className="vh-100 d-flex justify-content-center align-items-center text-center">
			<div>
				<p>An error occurred. Please check your internet connection and try again.</p>
				<Button onClick={() => window.location.reload()} icon={<ReloadOutlined />}>
					Refresh page
				</Button>
			</div>
		</div>
	)
}

export default NetworkErrorPage
