import axios from 'axios';
import authHeader from "./authHeader";


export default axios.create({
	baseURL: 'https://api.erector.emmanuelmaunga.dev/api/v1/',
	headers: {
		'Authorization': `Bearer ${authHeader()}`,
	}
})

export const auth = axios.create({
	baseURL: 'https://api.erector.emmanuelmaunga.dev/auth/',
})
